import React from 'react';
const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const DevelopmentOnly = ({ children }) => {
  if (activeEnv != 'development') {
    return null;
  }

  return children;
};

export default DevelopmentOnly;
