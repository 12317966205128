import React from 'react';
import { graphql } from 'gatsby';
import PostLayout from '../layouts/post-layout';

import styled from 'styled-components';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';

import { device } from '../utils/breakpoints';
import SEO from '../components/SEO';
import DevelopmentOnly from '../components/Development-Only';

const MainWrapper = styled.div`
  max-width: 640px;
  margin: auto;
  z-index: 1;
  margin-bottom: 40px;
`;

const ArticleHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ArticleDate = styled.p`
  color: var(--color-gray-500);
  font-size: 16px;
  margin-top: 4px;
`;

const ArticleTitle = styled.h1`
  font-size: 28px;

  @media ${device.small} {
    font-size: 36px;
  }
`;

const ArticleTemplate = ({ data, pageContext, location }) => {
  const post = data.post;
  // const [scrollRef, percentage] = useScrollPercent()
  // const { previous, next } = pageContext

  return (
    <PostLayout>
      <SEO
        title={`${post.frontmatter.title} - George Nance`}
        datePublished={post.frontmatter.isoPubDate}
        dateModified={post.frontmatter.isoPubDate}
        description={post.frontmatter.description}
        type="article"
        wordCount={post.wordCount.words}
        timeToRead={post.timeToRead}
        image={
          post.frontmatter.cover_image
            ? post.frontmatter.cover_image.publicURL
            : ''
        }
      ></SEO>

      <article className="h-entry">
        <ArticleHead className="article-head">
          <ArticleTitle className="p-name">
            {post.frontmatter.title}
          </ArticleTitle>
          {/* <DevelopmentOnly>
            <img height="400px" src={post.frontmatter.image.publicURL} />
          </DevelopmentOnly> */}
          <ArticleDate className="dt-published">
            {post.frontmatter.date}
          </ArticleDate>
        </ArticleHead>
        <MainWrapper>
          <section className="markdown e-content">
            <MDXRenderer>{post.body}</MDXRenderer>
          </section>
        </MainWrapper>
      </article>
    </PostLayout>
  );
};
export default ArticleTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    post: mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      body
      excerpt(pruneLength: 160)
      wordCount {
        words
      }
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        relativeDate: date(fromNow: true)
        isoPubDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
        description
        cover_image {
          publicURL
        }
      }
    }
  }
`;
