import React from 'react';

import Header from '../components/Header/header';

import Footer from '../components/Footer/Footer';
import SubscribeForm from '../components/SubscribeForm/SubscribeForm';
import styled from 'styled-components';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import FadeMask from '../assets/images/fade-mask.svg';
import { device } from '../utils/breakpoints';
import SEO from '../components/SEO';

const PostLayoutHeaderWrapper = styled.div`
  height: 40vh;
  position: relative;
  background-color: var(--color-muted);
  margin-top: -90px;
  margin-left: -1em;
  /* margin-right: -1em; */
  margin-bottom: 5vh;
  z-index: 3;
  /* position: absolute; */
`;

const FadeWrapper = styled.div`
  position: fixed;
  top: 80px;
  left: 0px;
  right: 0px;
  z-index: 2;
  pointer-events: none;
  display: none;

  @media (${device.medium}) {
    display: block;
  }
`;

const DaHeaderBar = styled.header`
  background-color: var(--color-background);
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 85px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 4em;
    justify-content: flex-center;
    & nav {
      margin-top: 2em;
    }
  }
`;

export default ({ children, data }) => {
  return (
    <>
      <SEO></SEO>
      <Header></Header>

      <PostLayoutHeaderWrapper></PostLayoutHeaderWrapper>

      <DaHeaderBar></DaHeaderBar>
      <FadeWrapper>
        <FadeMask />
      </FadeWrapper>
      <MaxWidthWrapper>{children}</MaxWidthWrapper>
      <SubscribeForm></SubscribeForm>
      <Footer></Footer>
    </>
  );
};
